@charset "UTF-8";
.l-app {
  background: #fff;
  color: #fff;
}
.l-app a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.l-app a i {
  margin-top: -8px;
}

.l-main {
  background: rgb(10, 10, 15);
  background-repeat: no-repeat;
  background-size: cover;
}

.l-header {
  display: flex;
  height: 90px;
  padding: 0 35px;
}

.l-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  gap: 10px;
}
.l-logo__img {
  height: 24px;
  width: 24px;
}
.l-logo__text {
  font-size: 30px;
  font-weight: 700;
}

.l-top-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-left: auto;
  width: auto;
  font-size: 15px;
}
.l-top-nav__login, .l-top-nav__account {
  border: 1px solid grey;
  border-radius: 10px;
  padding: 5px 10px;
}
.l-top-nav a:hover {
  color: aquamarine;
}

.l-top-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 90px);
  padding: 0 45px;
  gap: 15px;
}

.l-intro {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 15px;
}

.l-intro__title {
  font-size: 70px;
  font-weight: 600;
  line-height: 1;
}
.l-intro__subtitle {
  font-size: 20px;
}

.l-intro-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin: 15px 0 0 15px;
}
.l-intro-links__basic, .l-intro-links__pro {
  color: aquamarine;
  border: 1px solid aquamarine;
  border-radius: 10px;
  padding: 10px;
  width: 200px;
}
.l-intro-links__basic:hover, .l-intro-links__pro:hover {
  border-color: aqua;
}
.l-intro-links__basic:active, .l-intro-links__pro:active {
  transform: scale(1.05);
}
.l-intro-links__login {
  padding: 10px;
  color: aquamarine;
  border: 1px solid aquamarine;
  border-radius: 10px;
  width: 250px;
  font-weight: 600;
}
.l-intro-links__login:hover {
  background: aqua;
  color: rgb(17, 17, 17);
}
.l-intro-links__login:active {
  transform: scale(1.05);
}

.l-hero-img {
  flex: 0.75;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.l-features {
  height: 250px;
}

.select-set-primary {
  border: 1px solid rgba(72, 255, 215, 0.15) !important;
}

.select-set-secondary {
  border: 1px solid rgba(255, 238, 84, 0.15) !important;
}

.input-set-primary {
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
}

.input-set-core {
  border: 1px solid rgba(253, 94, 72, 0.15) !important;
}

.input-set-analysis {
  border: 1px solid rgba(114, 255, 71, 0.15) !important;
}

.input-set-gale {
  border: 1px solid rgba(221, 86, 255, 0.15) !important;
}

.input-set-vp {
  border: 1px solid rgba(62, 178, 255, 0.15) !important;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
  font-kerning: normal;
}
*:disabled {
  cursor: not-allowed;
}
* a {
  color: inherit;
  text-decoration: none;
}

button {
  border: initial;
  background-color: initial;
  color: inherit;
  font: inherit;
}
button:disabled {
  opacity: 0.6;
}

option:disabled {
  opacity: 0.5 !important;
}

.hidden,
.masked {
  display: none !important;
}

.blur {
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(230, 230, 230, 0.3);
  border-radius: 25px !important;
  border: 3px solid transparent;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(230, 230, 230, 0.3) transparent;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

select {
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: rgba(0, 0, 0, 0.7019607843);
  background-image: none;
  flex: 1;
  padding: 0 0.5em 2.9px 0.45em;
  color: beige;
  cursor: pointer;
  font-size: 14px;
}
select::-ms-expand {
  display: none;
}
select:disabled {
  color: rgba(232, 255, 198, 0.7);
}

.m-left {
  margin-left: -3.2px !important;
}

.m-right {
  margin-right: -8px !important;
}

.input-group-text {
  padding: 1px 5px !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  background-color: rgba(0, 0, 0, 0.4980392157) !important;
  border-radius: 0 !important;
  color: beige; /* market & analysis labels */
}
.input-group-text .ttip {
  font-size: 10px;
  margin-top: -4px;
}

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: auto;
  width: 100vw;
  background: rgb(0, 30, 50);
  color: grey;
  font-family: "Titillium Web", sans-serif;
}

.panel {
  width: 92vw;
  position: relative;
  height: 100vh;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 1;
}

.login-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 2;
  animation: fade-in-left 0.5s;
}
.login-overlay__cta {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  margin: 0 25px;
  background-color: transparent;
  color: whitesmoke;
  text-align: center;
}

.login-btn {
  color: #fff;
  border: 2px double transparent;
  background-image: linear-gradient(rgb(13, 14, 33), rgb(13, 14, 33)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  padding: 3px 14px 5px;
  width: 250px;
  border-radius: 25px;
  transition: all 0.2s;
}
.login-btn:hover {
  transform: scale(1.05);
}

.signup-btn {
  color: #fff;
  border: 2px double transparent;
  background-image: linear-gradient(rgb(56, 9, 9), rgb(56, 9, 9)), radial-gradient(circle at left top, rgb(217, 177, 0), rgb(1, 110, 218));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  padding: 3px 14px 5px;
  width: 250px;
  border-radius: 25px;
  transition: all 0.2s;
}
.signup-btn:hover {
  transform: scale(1.05);
}

.header {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
  margin-bottom: 0px;
  border-radius: 0;
}
.header__main {
  flex: 3.5;
  display: flex;
  justify-content: center;
}
.header__right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.app-name {
  flex: 2;
  display: inline-flex;
  align-items: center;
  align-self: center;
  height: 100%;
  padding-left: 25px;
  transition: all 0.2s;
}
.app-name__text {
  font-size: 33px;
  color: rgba(245, 245, 245, 0.8);
  font-weight: 700;
}

.connection-status-d {
  position: absolute;
  left: 20%;
  line-height: 70px;
  color: yellow;
  font-size: 11px;
  transition: all 0.2s;
}

.connection-status-m {
  display: none;
  position: absolute;
  top: 20px;
  left: 16.5%;
  color: yellow;
  font-size: 10px;
  transition: all 0.2s;
}

.profit-calculator {
  flex: 0.5;
  display: flex;
  justify-content: space-evenly;
}
.profit-calculator__btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  gap: 5px;
  transition: all 0.2s;
}
.profit-calculator__btn:hover {
  color: rgba(218, 165, 32, 0.9);
}
.profit-calculator__btn:active {
  transform: scale(1.05);
}
.profit-calculator__btn i {
  font-size: 20;
}
.profit-calculator__text {
  font-size: 12px;
}

.fund-account {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
}
.fund-account__deposit, .fund-account__withdraw {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  gap: 5px;
  transition: all 0.2s;
}
.fund-account__deposit:hover, .fund-account__withdraw:hover {
  color: rgba(218, 165, 32, 0.9);
}
.fund-account__deposit:active, .fund-account__withdraw:active {
  transform: scale(1.05);
}
.fund-account__deposit i, .fund-account__withdraw i {
  font-size: 20;
}
.fund-account__deposit--text, .fund-account__withdraw--text {
  font-size: 12px;
}

.connect-account {
  position: relative;
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: inherit;
  margin: 5px 25px 5px auto;
  border: 1px solid rgba(102, 102, 102, 0.3);
  border-radius: 25px;
  animation: fade-in 0.5s;
}
.connect-account i {
  font-size: 18px;
  margin-top: -8px;
  color: rgba(85, 255, 235, 0.8);
}
.connect-account__text {
  font-size: 16px;
  color: rgba(85, 255, 235, 0.8);
}
.connect-account:hover {
  background: rgba(102, 102, 102, 0.1);
}
.connect-account:active {
  transform: scale(1.02);
}

.account-section {
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
  height: inherit;
  width: auto;
  margin-left: auto;
  padding-right: 25px;
  animation: fade-in 0.5s;
}

.account-loader {
  position: absolute;
  top: 35%;
  right: 31.5%;
  font-size: 15px;
  color: aqua;
}

.account-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
  padding: 10px 0;
  transform: scale(0.9);
  margin-right: -10px;
}

.account-info {
  background: rgb(6, 6, 17);
  padding: 2px 10px;
  border-radius: 25px;
  width: inherit;
  flex: 1.4;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}
.account-info__tag {
  text-align: center;
  color: beige;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  padding: 0px 3px;
  font-size: 10px;
  transition: all 0.2s;
}
.account-info__select {
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  color: rgba(218, 165, 32, 0.9);
  background: inherit;
  transition: all 0.2s;
}
.account-info__select:disabled {
  color: rgba(218, 165, 32, 0.9) !important;
  opacity: 1 !important;
}
.account-info__id {
  font-weight: 400;
}

.account-balance {
  flex: 1;
  display: flex;
  gap: 10px;
}
.account-balance__info {
  color: rgba(85, 255, 235, 0.8);
  border: 0;
  border-radius: 0;
  padding: 0;
  margin-top: -4px;
  margin-bottom: -4px;
  font-size: 22px;
  font-weight: 600;
  transition: all 0.2s;
}

.content {
  display: flex;
}

.sidebar-left {
  width: 8vw;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: rgba(12, 12, 12, 0.8);
}

.app-tag {
  flex: 1;
  display: flex;
  min-height: 70px;
  max-height: 70px;
  justify-content: center;
  align-items: center;
  border-bottom: 0px solid rgba(255, 255, 255, 0.1);
}

.app-logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.app-logo__img {
  height: 25px;
  width: 25px;
  background-size: cover;
}

.app-plan {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-plan__text {
  color: rgba(255, 187, 0, 0.8);
  font-weight: 600;
}

.left-nav {
  flex: auto;
  display: flex;
  flex-direction: column;
}
.left-nav__utility {
  height: calc(60vh - 120px);
  display: flex;
  flex-direction: column;
}
.left-nav__footer {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 40vh;
}
.left-nav__copyright {
  display: flex;
  align-items: center;
  height: 7vh;
  line-height: 1.2;
  font-size: 14px;
  padding: 0 10px;
}

.utility-btn,
.footer-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70px;
  gap: 5px;
  font-size: 20px;
}
.utility-btn__text,
.footer-btn__text {
  font-size: 12px;
  font-weight: 500;
}
.utility-btn:hover,
.footer-btn:hover {
  color: rgb(0, 21, 112);
}
.utility-btn:active,
.footer-btn:active {
  transform: scale(1.05);
}

.main {
  flex: 3.5;
  display: flex;
  flex-direction: column;
}

.sidebar-right {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.summary-section {
  display: flex;
  flex-direction: column;
  height: calc(60vh - 120px);
  overflow: scroll;
  padding: 5px 5px 0;
  background: rgba(0, 0, 0, 0.8);
}

.summary-box {
  display: flex;
  height: auto;
  margin: 2.5px 5px 2.5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.avg-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  gap: 5px;
}
.avg-price__title {
  font-weight: 600;
  color: beige;
  border-radius: 25px;
  padding: 0px;
  display: inline-flex;
  gap: 3px;
}
.avg-price__title input {
  margin-top: -5px;
}
.avg-price__title h3 {
  font-size: 16px;
}
.avg-price__title i {
  margin-top: -3px;
}
.avg-price__info {
  font-size: 15px;
  font-weight: 500;
  color: lightcyan;
  margin-top: -13px;
  text-align: center;
}
.avg-price__diff {
  font-size: 12px;
  color: goldenrod;
}

.logs-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  background: rgba(0, 0, 0, 0.9);
}

.run-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 0 7px 0 5px;
  height: calc(100% - 20px);
}

.run-console {
  flex: 5;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.run-profit {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(107, 160, 0, 0.1);
  border-radius: 3px;
  color: rgb(116, 192, 1);
  font-size: 20px;
}

.bot-stats {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bot-stats__top, .bot-stats__bottom {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  width: 100%;
}
.bot-stats__label {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(25, 25, 25, 0.3);
  color: white;
  border-radius: 5px 5px 0 0;
}
.bot-stats__counter {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(15, 15, 15, 0.3);
  color: white;
  border-radius: 0 0 3px 3px;
}
.bot-stats__title, .bot-stats__value {
  font-size: 12px;
}

.trade-count,
.win-loss,
.total-stake,
.total-payout {
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 50%;
}

.bot-run {
  flex: 1;
  display: flex;
  justify-content: center;
  align-content: center;
}

.run-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(20, 20, 100);
  width: 100%;
  border-radius: 3px;
  transition: all 0.2s;
}
.run-button__text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: white;
}
.run-button__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -12.5px;
  font-size: 25px;
  color: white;
}
.run-button:hover {
  background: rgb(35, 35, 145);
}
.run-button:active {
  transform: scale(1.02);
}

.goal-display {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  height: 100%;
  padding: 3px 0;
  background: rgba(182, 201, 255, 0.08);
  border-radius: 3px;
}

.goal-text {
  display: flex;
  justify-self: start;
  align-items: center;
  gap: 5px;
  width: 190px;
  font-weight: 500;
}
.goal-text__goal {
  font-size: 13px;
  margin-right: auto;
}
.goal-text__running {
  font-size: 11px;
}

.goal-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.goal-bar__bar {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 200px;
  height: 15px;
  padding: 2px;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 25px;
}
.goal-bar__progress {
  background: rgb(116, 192, 1);
  height: 100%;
  width: 0%;
  min-width: 0%;
  max-width: 100%;
  border-radius: inherit;
  transition: all 0.2s;
}

.chart-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  height: calc(60vh - 120px);
  width: 100%;
}
.chart-section i {
  margin-top: -3px;
  /* possible because parent (chart-bar-text__top/bottom) are flex */
}

.chart-control {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  column-gap: 25px;
  width: 100%;
  height: 50px;
  padding: 10px;
  border-bottom: 0px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.8);
}

.market-index {
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
}
.market-index select {
  padding-top: 0.16em;
  font-size: 13.5px;
}
.market-index__text {
  font-size: 14px;
  font-weight: 500;
}
.market-index__selection {
  position: relative;
  display: flex;
  align-self: center;
  width: 165px;
  height: 28px;
  line-height: 0;
  background: transparent; /* affects opacity*/
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  animation: fade-in-left 0.5s;
}
.market-index__selection::after {
  display: none;
  content: "▼";
  position: absolute;
  font-size: 12px;
  top: 1em;
  right: -3px;
  padding: 0 1em;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}

.chart-range {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
}
.chart-range__text {
  font-size: 14px;
  font-weight: 500;
  flex: 1;
}
.chart-range__input {
  flex: auto;
  align-self: center;
  display: inline-flex;
  align-items: center;
  gap: 0;
}
.chart-range__input input {
  max-width: 75px;
  height: 28px !important;
  padding: 7px 10px !important;
  font-size: 13px !important;
  color: white !important;
  background-color: rgba(0, 0, 0, 0.3098039216) !important;
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
  border-right: 0 !important;
  border-radius: 5px;
  z-index: 1;
}
.chart-range__input .form-control {
  height: 20px;
  flex: 1;
  border-radius: 0;
  animation: fade-in-left 0.5s;
}
.chart-range__input .input-group-text {
  color: beige;
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  font-size: 12px;
  height: 28px;
  flex: 1;
  animation: fade-in-left 0.5s;
}

.chart-barrier {
  display: inline-flex;
  align-items: center;
  transition: all 0.2s;
}
.chart-barrier__text {
  font-size: 14px;
  font-weight: 500;
  flex: 1;
  margin-right: 8px;
}
.chart-barrier__input {
  flex: auto;
  align-self: center;
  display: inline-flex;
  align-items: center;
}
.chart-barrier__input input {
  width: 45px;
  height: 28px !important;
  padding: 7px !important;
  font-size: 13px !important;
  color: white !important;
  background-color: rgba(0, 0, 0, 0.3098039216) !important;
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
  border-left: 0 !important;
  border-radius: 5px;
  z-index: 1;
}
.chart-barrier__input .form-control {
  height: 20px;
  flex: 1;
  border-radius: 0;
  animation: fade-in-left 0.5s;
}
.chart-barrier__input .input-group-text {
  flex: 1;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 0 !important;
  height: 28px;
  animation: fade-in-left 0.5s;
}
.chart-barrier__minus {
  margin-left: 8px;
}

.chart-index {
  display: flex;
  align-items: center;
  gap: 8px;
}
.chart-index select {
  padding-top: 0.16em;
  font-size: 13.5px;
}
.chart-index__text {
  font-size: 14px;
  font-weight: 500;
}
.chart-index__selection {
  position: relative;
  display: flex;
  align-self: center;
  width: auto;
  height: 28px;
  line-height: 0;
  background: transparent; /* affects opacity*/
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  animation: fade-in-left 0.5s;
}
.chart-index__selection::after {
  display: none;
  content: "▼";
  position: absolute;
  font-size: 12px;
  top: 1em;
  right: -3px;
  padding: 0 1em;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}

.chart-display {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.chart-display__sidebar {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
  padding-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.3);
}
.chart-display__area {
  flex: 5;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  column-gap: 25px;
  row-gap: 15px;
  padding: 10px 10px 0;
}

.singlechart {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px 5px;
}

.quote-price {
  text-align: center;
}
.quote-price__text {
  font-weight: 600;
  color: beige;
  border-radius: 25px;
  padding: 0px;
  display: inline-flex;
  gap: 3px;
}
.quote-price__text h3 {
  font-size: 16px;
}
.quote-price__text i {
  margin-top: -3px;
}
.quote-price__value {
  font-size: 15px;
  font-weight: 500;
  color: lightcyan;
  margin-top: -10px;
}

.rf-chart {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}

.eo-chart {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}

.chart-bar-text {
  display: flex;
  align-self: end;
}
.chart-bar-text__top {
  display: flex;
  gap: 2px;
  margin-right: 5px;
  color: beige;
  border-radius: 25px;
  padding: 0px;
  font-size: 13px;
  font-weight: 500;
  align-self: center;
  align-items: center;
}
.chart-bar-text__top i {
  font-size: 10px;
}
.chart-bar-text__bottom {
  display: flex;
  gap: 2px;
  margin-right: 5px;
  color: beige;
  border-radius: 25px;
  padding: 0px;
  font-size: 13px;
  font-weight: 500;
  align-self: center;
  align-items: center;
}
.chart-bar-text__bottom i {
  font-size: 10px;
}

.chart-bar-value {
  display: flex;
  flex-direction: column;
  width: 80px;
  align-self: center;
  height: auto;
  justify-items: center;
}

.bar-rise,
.bar-even {
  display: inline-flex;
  height: 15px;
  background-color: rgba(0, 0, 255, 0.8);
  color: white;
  font-size: 12px;
  width: 0%;
  border-radius: 7px;
  transition: all 0.2s;
}

.bar-fall,
.bar-odd {
  display: inline-flex;
  height: 15px;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  font-size: 12px;
  width: 0%;
  border-radius: 7px;
  transition: all 0.2s;
}

.digit-rise,
.digit-even,
.digit-fall,
.digit-odd {
  margin-left: 10px;
  align-self: center;
}

.chartjs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.chartjs-render-monitor {
  width: 100% !important;
  height: 100% !important;
}

.longchart {
  display: inline-flex;
  transform: scale(1.5);
}
.longchart .col {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  width: 35px;
  height: 35px;
  font-weight: 400;
  line-height: 30px;
  padding: 2px;
  margin-left: 3px;
}
.longchart .col-title-sym {
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  width: 70px;
  height: 35px;
  line-height: 30px;
  padding: 2px;
  margin-left: 1px;
  margin-bottom: 3px;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.75);
  color: beige;
}
.longchart .col-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  width: 70px;
  height: 35px;
  line-height: 30px;
  padding: 2px;
  margin-left: 1px;
  margin-bottom: 3px;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.55);
  color: beige;
}

.longchart-texts {
  display: flex;
  flex-direction: column;
}

.longchart-values {
  display: flex;
  flex-direction: column;
}
.longchart-values__digits .col {
  background-color: rgba(0, 0, 0, 0.55);
  color: beige;
}
.longchart-values__over .col {
  background-color: rgba(0, 0, 0, 0.55);
  color: lightseagreen;
}
.longchart-values__matches .col {
  background-color: rgba(0, 0, 0, 0.55);
  color: gold;
}
.longchart-values__under .col {
  background-color: rgba(0, 0, 0, 0.55);
  color: orangered;
}

.bot-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
}

.bot-settings {
  flex: 3;
  display: flex;
  height: 100%;
  padding: 10px 0 10px 7px;
  transition: all 0.2s;
}
.bot-settings__tabs {
  flex: 1;
  max-width: 35px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.bot-settings__tab1 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  background-color: rgba(128, 128, 250, 0.3);
  transition: all 0.2s;
}
.bot-settings__tab1:active {
  transform: scale(1.02);
}
.bot-settings__tab2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.2s;
}
.bot-settings__tab2:active {
  transform: scale(1.02);
}
.bot-settings .bs-tab1-text {
  transform: rotate(-90deg);
}
.bot-settings .bs-tab2-text {
  transform: rotate(-90deg);
}
.bot-settings__group {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  transition: all 0.2s;
}
.bot-settings__group .input-group-text {
  color: beige;
  border-right: 0 !important;
  border-radius: 0 !important;
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.8) !important;
  height: 32px;
  animation: fade-in 0.5s;
}
.bot-settings__summary {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  margin-left: 10px;
  width: 100%;
  overflow: scroll;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  transition: all 0.2s;
}
.bot-settings .form-control {
  font-size: 13.5px !important;
  margin-left: 0.0001px !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  color: rgb(105, 172, 105) !important;
  background-color: rgba(15, 15, 15, 0.5) !important;
  height: 32px;
  animation: fade-in 0.5s;
}
.bot-settings .form-control:disabled {
  color: grey !important;
}
.bot-settings__input-blocks {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}
.bot-settings__input-blocks .input-group {
  width: 48.5%;
}
.bot-settings__selection {
  position: relative;
  display: flex;
  justify-content: end;
  width: 48.5%;
  height: 32px;
  font-size: 14px;
  line-height: 0;
  overflow: hidden;
  color: beige !important;
  border-radius: 0;
  animation: fade-in 0.5s;
}
.bot-settings__selection::after {
  display: none;
  content: "▼";
  position: absolute;
  font-size: 12px;
  top: 12.5px;
  right: -3px;
  padding: 0 1em;
  color: rgba(255, 255, 255, 0.8) !important;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}

.bot-summary {
  display: flex;
  width: 100%;
  flex: auto;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.bot-logs {
  position: relative;
  flex: auto;
  display: flex !important; /* to enable corresponding height for the child div */
  width: auto;
  height: 100%;
  transition: all 0.2s;
}
.bot-logs__group {
  width: 100%;
  overflow-y: scroll;
  margin: 5px 0;
}
.bot-logs__text {
  font-family: "IBM Plex Mono", monospace;
  font-size: 11px;
  font-weight: 400;
  color: rgba(245, 245, 220, 0.7);
  padding: 5px 10px;
  margin: 0;
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.2);
  animation: fade-in 0.5s;
}
.bot-logs__text:last-child {
  border-bottom: 0px;
}

.log-win {
  color: rgba(2, 255, 2, 0.7);
}

.log-error {
  color: rgba(255, 255, 2, 0.7);
}

.log-loss {
  color: rgb(255, 50, 50);
}

.win-value {
  color: rgb(2, 255, 2);
}

.loss-value {
  color: rgb(255, 2, 2);
}

.clear-bot-stats {
  display: flex;
  position: absolute;
  width: auto;
  border-radius: 3px;
  padding: 0 5px 2px;
  font-size: 12px;
  right: 6px;
  bottom: 6px;
  line-height: auto;
  background-color: rgb(25, 25, 45);
  color: rgba(245, 245, 220, 0.8);
}
.clear-bot-stats span {
  margin-top: -2.5px;
}
.clear-bot-stats:active {
  transform: scale(1.02);
}

@media (max-width: 1025px) {
  .panel {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
}
@media (max-width: 1170px) {
  .chart-display {
    gap: 1px;
    align-items: center;
  }
  .singlechart {
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    align-items: start;
  }
  .quote-price {
    align-self: center;
  }
}
@media (max-width: 800px) {
  .bot-section {
    gap: 10px;
  }
  .bot-settings {
    flex: 2;
  }
  .bot-settings__selection {
    width: 97%;
  }
  .bot-settings__input-blocks .input-group {
    width: 97%;
  }
}
@media (max-width: 670px) {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    background: rgb(0, 7, 17);
    z-index: 2;
  }
  .logo__text {
    font-size: 27px;
    display: none;
  }
  .how-to-btn {
    left: 25%;
  }
  .how-to-info {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    max-height: 100vh;
    border: none;
    font-weight: 300;
  }
  .chart-section {
    background-color: rgba(0, 0, 0, 0.75);
    margin-top: 67px;
  }
  .chart-display {
    gap: 15px;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
  }
  .singlechart {
    justify-content: space-between;
    flex-direction: row-reverse;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  .quote-price {
    margin: 0;
  }
  .market-index {
    flex: 1;
  }
  .market-index__text {
    display: none;
  }
  .chart-range {
    flex: 1;
  }
  .chart-range__text {
    display: none;
  }
  .bot-section {
    flex-direction: column;
    padding: 0;
  }
  .bot-settings {
    flex: auto;
    height: auto;
    animation: fade-in-left 0.5s;
  }
  .bot-settings__selection {
    width: 48.5%;
  }
  .bot-settings__group {
    border-color: transparent;
  }
  .bot-settings__input-blocks .input-group {
    width: 48.5%;
  }
  .bot-transactions {
    flex-direction: column-reverse;
    margin-bottom: 90px;
    animation: fade-in-left 0.5s;
  }
  .bot-logs {
    height: 300px;
    max-height: 300px;
    width: 96vw;
  }
}
@media (max-width: 490px) {
  .input-group-text {
    padding: 1px 7px !important;
  }
  .header {
    min-height: 60px;
    max-height: 60px;
    padding: 0 15px;
  }
  .how-to-btn {
    top: 17px;
    left: 21.5%;
    transform: scale(0.75);
  }
  .how-to-btn:hover {
    transform: scale(0.8);
  }
  .account-loader {
    right: 45px;
  }
  .account-group {
    margin-right: -27px;
    transform: scale(0.75);
  }
  .account-info {
    padding: 2px 5px;
  }
  .account-balance {
    padding: 2px 5px;
  }
  .connection-status-d {
    display: none;
  }
  .connection-status-m {
    display: block;
  }
  .chart-section {
    margin-top: 57px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .chart-range__input .input-group-text {
    padding-left: 15px !important;
  }
  .longchart .col {
    height: 30px;
    width: 30px;
    font-size: 11px;
    padding: 0px 1px;
  }
  .longchart .col-title {
    height: 30px;
    width: 60px;
    font-size: 11px;
  }
  .quote-price__text {
    font-size: 14px;
  }
  .bot-settings {
    flex: 2;
  }
  .bot-settings__group {
    padding: 10px 5px;
  }
  .bot-settings__selection {
    width: 97%;
  }
  .bot-settings__input-blocks {
    justify-content: space-evenly;
  }
  .bot-settings__input-blocks .input-group {
    width: 97%;
  }
  .bot-logs__text {
    text-align: justify;
    font-size: 11.5px;
  }
}
@media (max-width: 425px) {
  .chart-display {
    padding: 0;
  }
  .singlechart {
    transform: scale(0.8);
    margin-right: -50px;
    margin-left: -50px;
  }
  .chart-control {
    width: 97%;
    flex-direction: column;
    gap: 5px;
  }
  .market-index {
    width: 100%;
  }
  .market-index__selection {
    width: 100%;
  }
  .chart-range {
    width: 100%;
    justify-content: end;
  }
  .chart-range__text {
    display: block;
    font-size: 14px;
  }
  .chart-range__input input {
    max-width: 150px;
  }
  .longchart {
    max-width: 96vw;
  }
  .longchart .col {
    font-size: 11px;
    padding: 0 2px;
  }
  .longchart .col-title-sym {
    display: flex;
    height: 30px;
    width: 17.5px;
    font-size: 11px;
    padding: 0 2px;
  }
  .longchart .col-title {
    display: none;
  }
}
@media (max-width: 380px) {
  .longchart .col {
    padding: 0 1px;
    font-size: 11px;
    margin-left: 1.5px;
    margin-bottom: 1.5px;
  }
  .longchart .col-title-sym {
    font-size: 11px;
    padding: 0 1px;
    margin-left: 0px;
    margin-bottom: 1.5px;
  }
}
@media (max-width: 360px) {
  .longchart .col {
    font-size: 10px;
  }
  .longchart .col-title-sym {
    font-size: 10px;
  }
}
